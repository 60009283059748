<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/pt-br';

import { getSales } from '@/api/Sales.js'
import { Sale } from "@/models/Sale";
import { Commission } from "@/models/Commission";

/**
 * Basic-table component
 */
export default {
  page: {
    title: "Vendas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, DatePicker },
  data() {
    return {
      title: "Vendas",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Vendas",
          active: true,
        },
      ],
      list:[],
      commission: 0,
      total: 0,
      commissionable: 0,
      params: {
        period: null
      },
      loading: false
    };
  },
  computed: {
    hasItems: function () {
      return this.list.length > 0
    }
  },
  created () {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this

      this.list = []
      this.commission = 0
      this.total = 0
      this.commissionable = 0
      this.loading = true

      getSales(this.params).then(response => {
        vm.list = response.sales.map(item => {
          return new Sale(item)
        })

        vm.commission = response.commission
        vm.total = response.total
        vm.commissionable = response.commissionable

        this.loading = false
      })
    },
  },
  watch: {
    "params.period": function (){
      this.fetchData();
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="title"
        :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-9">
<!--                <h5 class="card-title mb-3 mt-2">Setembro de 2020</h5>-->
              </div>
              <div class="col-lg-3">
                <div role="group" class="input-group mb-3 pull-right">
                  <date-picker
                      :type="'month'"
                      v-model="params.period"
                      :first-day-of-week="1"
                      lang="pt-BR"
                      :format="'MMMM YYYY'"
                      :value-format="'YYYY-MM-DD'"
                      :default-value="new Date()"
                      :editable="false"
                      :minimum-view="'month'"
                  />
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Descrição</th>
                  <th class="text-center">Status</th>
                  <th>Data</th>
                  <th class="text-right">Subtotal</th>
                  <th class="text-right">Desconto</th>
                  <th class="text-right">Valor Final</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                  <td colspan="100%" class="text-center">
                    <p class="py-4 mb-0">Carregando resultados...</p>
                  </td>
                </tr>

                <tr v-if="!hasItems && !loading">
                  <td colspan="100%" class="text-center">
                    <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
                  </td>
                </tr>
                <tr v-else v-for="item in list" v-bind:key="item.id">
                  <th scope="row" class="min">
                    {{ item.id }}
                  </th>
                  <td>
                    <router-link
                        tag="a"
                        :to="`/companies/${item.order.company.id}`"
                        class="text-primary"
                    >
                      {{ item.order.company.name }}
                    </router-link><br/>
                    <small>1x Plano Intermediário (11/08/2020 à 11/09/2020)</small>
                  </td>
                  <td class="text-center">
                    <span class="badge badge-success" v-if="item.discount == 0">Comissão</span>
                    <span class="badge badge-warning" v-else>Desconto</span>
                  </td>
                  <td class="min">{{ $d(item.created_at, 'short') }}</td>
                  <td class="text-right">{{ $n(item.amount, 'currency', 'brl')}}</td>
                  <td class="text-right">{{ $n(item.discount, 'currency', 'brl')}}</td>
                  <td class="text-right">{{ $n(item.amount - item.discount, 'currency', 'brl')}}</td>
                </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td class="text-right" colspan="6"><h6 class="mb-0">Total</h6></td>
                    <td class="text-right"><h6 class="mb-0">{{ $n(total, 'currency', 'brl')}}</h6></td>
                  </tr>
                  <tr>
                    <td class="text-right" colspan="6"><h6 class="mb-0">Total Comissionável</h6></td>
                    <td class="text-right"><h6 class="mb-0">{{ $n(commissionable, 'currency', 'brl')}}</h6></td>
                  </tr>
                  <tr>
                    <td class="text-right" colspan="6"><h6 class="mb-0">Faixa de Comissão</h6></td>
                    <td class="text-right"><h6 class="mb-0">{{ commission }}%</h6></td>
                  </tr>
                  <tr>
                    <td class="text-right" colspan="6"><h5 class="mb-0">Valor da Comissão</h5></td>
                    <td class="text-right"><h5 class="mb-0">{{ $n(commissionable * commission / 100, 'currency', 'brl')}}</h5></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
