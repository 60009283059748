import {Invoice} from "./Invoice";
import {Partner} from "./Partner";
import {Order} from "./Order";

export class Sale {

  constructor(saleObj) {
    if(typeof saleObj === 'undefined' || saleObj === null){
      saleObj = {};
    }

    this.id           = saleObj.id || null;
    this.partner_id   = saleObj.partner_id || null;
    this.order_id     = saleObj.order_id || null;
    this.amount       = saleObj.amount || null;
    this.discount     = saleObj.discount || 0;
    this.currency     = saleObj.currency || null;
    this.status       = saleObj.status || null;

    this.order        = new Order();
    this.invoice      = new Invoice();
    this.partner      = new Partner();

    this.total        = this.amount - this.discount;

    switch (saleObj.status) {
    case 'pendding':
      this.class = 'bg-warning-lighter';
      break;
    case 'approved':
      this.class = 'bg-accent-lighter';
      break;
    case 'processed':
      this.class = 'bg-success-lighter';
      break;
    }

    this.labelStatus = 'global.status.' + this.status;

    if(typeof saleObj.order !== "undefined"){
      this.order = new Order(saleObj.order);
    }

    if(typeof saleObj.invoice !== "undefined"){
      this.invoice = new Invoice(saleObj.invoice);
    }

    if(typeof saleObj.partner !== "undefined"){
      this.partner = new Partner(saleObj.partner);
    }

    if(typeof saleObj.created_at !== "undefined"){
      this.created_at = new Date(saleObj.created_at);
    }
  }

  static init(saleObj) {
    return new Sale(saleObj);
  }
}

